/* Container for the search bar and search results */
.search-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center contents horizontally */
    width: 100vw;
}

/* Styling for the search bar */
#search {
  
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    font-family: 'Courier Prime', monospace;
    margin-top: env(safe-area-inset-top); /* Add margin to avoid cut off on iPhone */
}

/* Ensure the input and button stay on the same line */
.search-container .search-bar .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-container .search-bar .form-control {
    flex-grow: 1;
}

/* Dynamic font size based on input length */
#searchInput {
    background-color: #f0f0f0;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    border-color: transparent;
    border-radius: 10px;
    color: black;
    max-width: 800px;
    font-family: 'Courier Prime', monospace;
    font-size: 16px; /* Set the font-size to 16px or larger */
    height: 32px;
}

/* Smaller screens, maintain minimum font size of 16px */


/* Styling for the search button */
#standardButtonSearch {
    background-color: #f0f0f0;
    border-radius: 20px;
    border: 0;
    margin-right: 5px;
    margin-left: 10px;
    font-family: var(--site-font);
    padding: 8px 16px; /* Ensure the button has appropriate padding */
    white-space: nowrap; /* Prevent button text wrapping */
    display: none; /* Hide the button by default */
    transition: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
    background-color: transparent;
  
    touch-action: manipulation;
}

@media (min-width: 769px) {
    #standardButtonSearch {
        display: inline-block; /* Show the button on desktop devices */
    }
}

/* Styling for the search result bubble */
.search-result {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    border-radius: 20px;
    background-color: #fff;
    width: 100%; /* Ensure it takes full width of the container */
    max-width: 780px; /* Max width of the bubble */
    text-align: center; /* Center text inside the bubble */
}

@media (max-width: 600px) {
    .search-result {
        width: 100%; /* Full width on smaller screens */
    }
}

.search-result:hover {
    background-color: #f9f9f9;
}

/* Styling for links inside the search result */
.search-result a {
    color: black;
    text-decoration: underline;
}

.search-result a:hover {
    text-decoration: underline;
}

/* Styling for the modal overlay and modal content */
.search-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.search-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: black;
    transition: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
    background-color: transparent;
  
    touch-action: manipulation;
}

.search-result-content {
    margin-top: 20px;
}

#backButton
{
    background-color: #f0f0f0;
    border-radius: 20px;
    border: 0;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    font-family: var(--site-font);
    display: inline-block; /* Use inline-block instead of inline-flex */
    color: black;
    white-space: nowrap; 
    text-align: center; /* Centers the text inside the button */
    margin: 10px;
    transition: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
    background-color: transparent;
  
    touch-action: manipulation;
    
}

@media (max-width: 330px) {
    #searchInput {
        font-size: 16px; /* Ensure the font-size is not smaller than 16px */
    }
}

@media (max-width: 430px) {
    #searchInput {
        width: 340px;
        
        font-size: 16px; /* Ensure the font-size is not smaller than 16px */
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    #searchInput {
        width: 70%;
        
    }
}

@media (min-width: 1025px) {
    #searchInput {
        width: 690px;
        
    }
}


