/* General padding for the main content containers */
.book-page {
  padding-left: 20px; /* Add padding to the left */
  padding-right: 20px; /* Add padding to the right */
}

.learning-materials {
  padding-left: 20px; /* Add padding to the left */
  padding-right: 20px; /* Add padding to the right */
}

/* Button container to align Library and Read buttons next to each other and add padding above */
.button-container {
  display: flex; /* Use flexbox to align buttons next to each other */
  justify-content: flex-start; /* Align buttons to the left */
  gap: 10px; /* Add some space between the buttons */
  margin-bottom: 20px; /* Add some margin below the buttons */
  padding-top: 20px; /* Add padding above the buttons */
}

/* Book button styles */
#bookButton {
  background-color: #f0f0f0;
  border-radius: 20px;
  margin-right: 5px;
  margin-left: 10px;
  font-family: var(--site-font);
  padding: 10px 20px;
  border: 1px solid #ccc;
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  gap: 5px; /* Space between icon and text */
  transition: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
  
}

#bookButton svg {
  margin-right: 5px; /* Adjust the icon margin to better align the text */
  vertical-align: middle; /* Align the icon vertically in the middle of the text */
}

#bookButton:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
}

/* Modal styles */
.search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.search-modal {
  background-color: white;
  padding: 20px;
  padding-left: 30px; /* Add extra padding to the left side */
  padding-right: 30px; /* Add extra padding to the right side */
  border-radius: 8px;
  max-width: 80%;
  width: 500px; /* Slightly reduce the width */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.search-modal .close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

/* Search result content styles */
.search-result-content {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

}

.search-result-content .form-control {
  width: 100%;
  font-size: 1rem; /* Slightly reduce the font size */
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: none;
}

.search-result-content button {
  align-self: center;
  font-size: 1rem; /* Reduce the font size */
  padding: 8px 16px; /* Reduce padding for a smaller button */
  transition: none;
  color: white;
  border: none;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

.search-result-content button:hover {
  /* background-color: #0056b3; */
}

/* Modal custom styles */
.custom-modal .modal-dialog {
  position: relative;
  margin: auto;
  width: 80%; /* Adjust the width as needed */
}

.custom-modal .modal-content {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.book-details
{
  margin-left: 20px;
}


.summary-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.summary-modal {
  background-color: white;
 padding-left: 20px;
padding-right: 20px;
 border-radius: 8px;
 
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.summary-modal .close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
  transition: none;
}

.summary-result-content {

  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.summary-result-content .form-control {
  width: 100%; /* Ensure the textarea takes full width */
  max-width: 90%; /* Limit the max width to avoid overflow */
  font-size: 1rem; /* Slightly reduce the font size */
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: none;
}

.summary-result-content button {
  align-self: center;
  font-size: 1rem; /* Reduce the font size */
  padding: 8px 16px; /* Reduce padding for a smaller button */
  transition: none;
  color: white;
  border: none;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

.summary-result-content button:hover {

}

.summary-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media (max-width: 430px) {
  #spacer2 {
    display: block; /* Display the div */
    height: 95px;   /* Set the height */
    width: 100%;    /* Optional: Ensure it takes full width */
    background-color: transparent
  }
}
