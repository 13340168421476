html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Courier Prime', monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  background-color: #f0f0f0;
}

#searchHome {
  padding: 10px 15px;
  /* width: 100%; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  font-family: 'Courier Prime', monospace;
  margin-top: env(safe-area-inset-top); /* Add margin to avoid cut off on iPhone */
}

#searchInputHome {
  background-color: #f0f0f0;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
  border-color: transparent;
  border-radius: 10px;
  color: black;
  width: 100%;
  max-width: 800px;
  font-family: 'Courier Prime', monospace;
}

@media (max-width: 393px) {
  #searchInputHome {
     width: 336px;
    /* mobile width: 336px; */
    /* desktop 376*/
    height: 32px;
    font-size: 16px;
  }
}

@media (max-width: 430px) {
  #searchInputHome {
    width: 350px;
    height: 32px;
    font-size: 16px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  #searchInputHome {
    width: 70%;
    height: 38px;
  }
}

@media (min-width: 1025px) {
  #searchInputHome {
    width: 800px;
    height: 38px;
  }
}

.subject-buttons-wrapper {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.subject-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 2px 0;
  transition: none;
}

.subject-buttons button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 5px;
  padding: 10px 20px;
  transition: none;
  font-family: var(--site-font);
  /* transition: background-color 0.3s, border-color 0.3s; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

.subject-buttons button.active {
  background-color: #f0f0f0;
  color: black;
  border-color: #007bff;
}

.subject-buttons button:hover {
  background-color: #e0e0e0;
}

.subject-buttons button:focus {
  outline: 2px solid #007bff;
}

#alphabeticalButton {
  background-color: #f0f0f0;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px 20px;
  transition: none;
  font-family: var(--site-font);
  /* transition: background-color 0.3s, border-color 0.3s; */
}

#readingListButton {
  background-color: #f0f0f0;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin: 10px 0;
  margin-left: 10px;
  padding: 10px 20px;
  color: black;
  font-family: var(--site-font);
  /* transition: background-color 0.3s, border-color 0.3s; */
  display: flex;
  align-items: center;
  gap: 5px;
  transition: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

#readingListButton .sort-icon {
  font-size: 18px;
}

.remove-button{
  transition: none;
  color: black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}



#alphabeticalButton.active {
  background-color: #f0f0f0;
  color: black;
  border-color: #007bff;
}

#alphabeticalButton:hover {
  background-color: #e0e0e0;
}

.sort-icon {
  font-size: 18px;
}

.book-item a {
  color: black;
  text-decoration: underline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

.book-item a:hover {
  text-decoration: underline;
}

.book-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  transition: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

#bookButton {
  background-color: #f0f0f0;
  border-radius: 20px;
  margin-right: 5px;
  margin-left: 10px;
  font-family: var(--site-font);
  padding: 10px 20px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

@media only screen and (max-device-width: 480px) {
  .subject-buttons button,
  #alphabeticalButton, #bookButton {
    color: black !important;
  }
}

.center-button {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  
  gap: 10px;
}

.book-list {
  max-width: 800px;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.book-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
  border-radius: 20px;
  background-color: #fff;
width: 780px;
}

@media (max-width: 600px) {
  .book-item {
    width: 390%;
  }
}



.book-item:hover {
  background-color: #f9f9f9;
}

.epub-container {
  max-width: 800px;
  margin: 10px auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.navigation-controls {
  text-align: center;
}

.navigation-controls button {
  transition: none;
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

#standardButton {
  background-color: #f0f0f0;
  border-radius: 20px;
  border: 0;
  margin-right: 5px;
  margin-left: 10px;
  font-family: var(--site-font);
  transition: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

  @media (max-width: 430px) {
    #spacer {
      display: block; /* Display the div */
      height: 75px;   /* Set the height */
      width: 100%;    /* Optional: Ensure it takes full width */
      background-color: #f0f0f0; /* Optional: Add background color for visibility */
    }
  }

  @media (max-width: 430px) {
    #spacer2 {
      display: block; /* Display the div */
      height: 95px;   /* Set the height */
      width: 100%;    /* Optional: Ensure it takes full width */
      background-color: transparent
    }
  }


  #authorHeading
  {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    margin: auto;
  }


  #authorBackButton{
    transition: none;
      background-color: #f0f0f0;
      border-radius: 20px;
      margin-right: auto;
      padding-left: 10px;
      font-family: var(--site-font);
      border: 0px solid #ccc;
      align-items: center;
      justify-content: center;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
      background-color: transparent;
    
      touch-action: manipulation;
  }



