.question {
    margin-bottom: 20px;
  }
  
  .question p {
    font-weight: bold;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 5px 0;
  }

  #bookButton {
    background-color: #f0f0f0;
    border-radius: 20px;
    margin-right: 5px;
    margin-left: 10px;
    font-family: var(--site-font);
    padding: 10px 20px;
    border: 1px solid #ccc;
    display: flex; /* Added display flex */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    gap: 5px; /* Added gap between icon and text */
  }
  
