/* General styling for the Note Page */
.note-page {
  padding: 20px;
  text-align: center; /* Center all text and inline elements within the .note-page */
}

.book-notes {
  margin-bottom: 20px;
}

.search-result {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto; /* Center the notes horizontally */
}

.search-result:hover {
  background-color: #f9f9f9;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #000; /* Default color */
}

.delete-button:hover {
  /* color: #ff0000;  */
}

.note-page h1 {
  text-align: center; /* Center the book title */
}

.note-page-buttons {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px;
  margin-bottom: 20px;
}

.note-page-buttons button {
  min-width: 100px; /* Set a minimum width to ensure buttons don't shrink */
  padding: 10px 20px; /* Adjust padding for consistent button size */
}

/* Add a media query for smaller screens */
@media (max-width: 768px) {
  .search-result {
    width: 90%; /* Reduce the width to fit the mobile screen */
    margin-left: auto;
    margin-right: auto; /* Center the element horizontally */
  }
  
  .note-page-buttons {
    gap: 10px; /* Keep the buttons side by side but maintain the gap */
  }
  
  .note-page h1 {
    font-size: 1.5rem; /* Adjust title size for smaller screens */
  }
  
  .delete-button {
    font-size: 20px; /* Adjust delete button size for smaller screens */
  }
}

@media (max-width: 600px) {
  .book-item {
    width: 390px;
  }
}

.add-note-content .form-control {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px; /* Add padding to the left */
  padding-right: 20px; /* Add padding to the right */
}

/* Styling for the Summary Modal */
.summary-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.summary-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max-height */
  position: relative;
}

.summary-modal h2 {
  margin-top: 0;
}

.summary-modal .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
