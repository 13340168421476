General page styles
body.dark-mode {
    background-color: #000;
    color: #fff;
  }
  
  /* Container for the EPUB view */
  .epub-containerTEST {
    position: relative;
    height: 600px; /* Adjust height as needed */
    width: 100%;
    border: 1px solid #ccc;
    margin-top: 20px;
  }
  
  /* Navigation button styles */
  .navigation-controls {
   
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .nav-button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
    color: #000;
    font-size: 16px;
  }
  
  body.dark-mode .nav-button {
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
  }
  
  /* Dark mode toggle button */
  .toggle-dark-mode-btn {
    margin-bottom: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  body.dark-mode .toggle-dark-mode-btn {
    background-color: #0056b3;
  }
  
  /* EPUB content styles */
  .epub-content {
    background-color: #fff;
    color: #000;
  }
  
  body.dark-mode .epub-content {
    background-color: #000;
    color: #fff;
  }
  
  /* Styles inside the iframe */
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  body.dark-mode iframe {
    background-color: #000;
  }
  
  /* Specific styles for the Project Gutenberg ebook content */

  body {
    background-color: #f0f0f0;

  }