.search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.search-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  width: 500px; /* Slightly reduce the width */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.search-modal .close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
  transition: none;
}

.search-result-content {

  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.search-result-content .form-control {
  width: 100%; /* Ensure the textarea takes full width */
  max-width: 90%; /* Limit the max width to avoid overflow */
  font-size: 1rem; /* Slightly reduce the font size */
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: none;
}

.search-result-content button {
  align-self: center;
  font-size: 1rem; /* Reduce the font size */
  padding: 8px 16px; /* Reduce padding for a smaller button */
  transition: none;
  color: white;
  border: none;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;
}

.search-result-content button:hover {

}

/* Modal custom styles */
.custom-modal .modal-dialog {
  position: relative;
  margin: auto;
  width: 80%; /* Adjust the width as needed */
}

.custom-modal .modal-content {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

#standardButtonNav {
  background-color: #f0f0f0;
  border-radius: 20px;
  border: 0;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
  margin: 8px;
  font-family: var(--site-font);
  display: inline-block; /* Use inline-block instead of inline-flex */
  color: black;
  padding: 10px 20px;
  white-space: nowrap;
  width: 150px;
  text-align: center; /* Centers the text inside the button */
  font-size: 14px;
  transition: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;

}

#standardButtonNav svg {
  margin-right: 5px; /* Adjust the icon margin to better align the text */
  vertical-align: middle; /* Aligns the icon vertically in the middle of the text */
  display: inline-block; /* Keeps the icon spacing consistent */
}


#standardButtonNav i {
  margin-right: 5px; /* Adjust the icon margin to better align the text */
  vertical-align: middle; /* Aligns the icon vertically in the middle of the text */
  display: inline-block; /* Keeps the icon spacing consistent */
}

#pageNavigation
{
  position: relative;
  bottom: 28px;
  text-align: center;
}

#navigationButton
{
  outline: none;
  background-color: transparent;
  border: 0px;
  width: 20%;
  color: rgba(0, 0, 0, 0.65);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
  background-color: transparent;

  touch-action: manipulation;

}

#navigationButton:focus {
  outline: none;
  box-shadow: none;
}


#navigationButton:active, 
#navigationButton:focus {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.65) !important;
  outline: none;
  box-shadow: none;
}