.footer {

    color: black;
    text-align: center;
    padding: 20px 0;
  }
  
  #footerNav {
    justify-content: center;
    width: 100%;
  }
  
  #footerContent {
    color: black;
  }

  :root {

    overscroll-behavior: none; /* Disable overscroll behavior */
  }
  
  #root
  {

  }
  
  #standardButton {
    display: inline-flex;
    align-items: center;
    color: black;
    padding: 10px 20px;
    margin: 10px 0;
  }
  
  #donateIcon {
    padding-right: 2px;
    color: red;
  }
  
  .nav-link {
    color: white;
    padding: 0 10px; 
  }
  
  .nav-link:hover {
    color: #ff6347; 
  }
  