#standardButton {
    background-color: #f0f0f0;
    border-radius: 20px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    margin-right: 5px;
    margin-left: 10px;
    font-family: var(--site-font);
    margin-top: 20px;
    transition: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Fully transparent tap highlight */
    background-color: transparent;
  
    touch-action: manipulation;
  }
  
/* */

  #aboutHeading {

    text-align: center; /* Center the text horizontally */
    font-family: var(--site-font); /* Ensure consistent font */
  }

  #infoHeading {
    padding: 20px; /* Adjust padding as needed */
    text-align: center; /* Center the text horizontally */
    font-family: var(--site-font); /* Ensure consistent font */
  }